<template>
	<div>
		<div class="header-section icon-list detailed">
			<div class="header-with-tabs row-space-tbf">
				<div class="space-left"></div>
                <div class="content">
					<slot />

					<template v-if="soft_procedures.is_active">
						<div class="actions actions-dropdown">
							<div class="actions-page mobile">
								<button class="btn-tbf grey filter-btn" v-bind:class="{active: extendFilters, 'only-icon': $resize && $mq.below(1385)}" @click="extendFilters = !extendFilters">
									<template v-if="$resize && $mq.above(1386)">
										<div class="counter" v-bind:class="{active: queryFiltersCount > 0}">{{ queryFiltersCount }}</div>
										<span class="text">{{ $t('general.filters') }}</span>
										<div class="icon-filter">
											<icon-arrow />
										</div>
									</template>
									<div class="icon" v-else><icon-settings /></div>
								</button>
							</div>
						</div>
						<div class="actions without-margin-left">
							<button class="btn-tbf blue center" v-bind:class="{'only-icon': $resize && $mq.below(1385)}" @click="donwloadXLS">
								<span class="text" v-if="$resize && $mq.above(1386)">{{ $t('reports.download_xls') }}</span>
								<icon-download class="icon" v-else />
							</button>
						</div>
					</template>
                </div>
                <div class="space-right"></div>
            </div>
		</div>

		<template v-if="soft_procedures.is_active">
			<div class="header-filter" :class="[{'filter-active': extendFilters}]" v-if="loaded">
				<div class="row-space-tbf filters-extend" :class="[{mobile: $resize && $mq.below(500)}]" v-if="extendFilters">
					<div class="space-left"></div>
					<div class="content">
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedRoles.length">{{ $t('filters.by_roles') }}</span>
								<span class="text" v-else-if="selectedRoles.length == 1">{{ filterRoles.find(el => el.id == selectedRoles[0]).name }}</span>
								<span class="text" v-else>{{ selectedRoles.length }} {{ $t('filters.roles_selected') }}</span>

								<div class="icon-filter" v-if="!selectedRoles.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedRoles')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="search-filter-dropdown">
									<input type="text" v-model="searchRoles" :placeholder="$t('filters.search_roles')">
								</div>

								<template v-for="role in filteredRoles">
									<div class="dropdown-item" @click.stop="selectOptionFilter('selectedRoles', role.id)" v-bind:class="{active: selectedRoles.includes(role.id)}" :key="'filter-role-' + role.id">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ role.name }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedProcedures.length">{{ $t('filters.by_procedures') }}</span>
								<span class="text" v-else-if="selectedProcedures.length == 1">{{ filterProcedures.find(el => el.id == selectedProcedures[0]).name }}</span>
								<span class="text" v-else>{{ selectedProcedures.length }} {{ $t('filters.procedures_selected') }}</span>

								<div class="icon-filter" v-if="!selectedProcedures.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedProcedures')" v-else><icon-close /></div>
							</button>

							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="search-filter-dropdown">
									<input type="text" v-model="searchProcedures" :placeholder="$t('filters.search_procedures')">
								</div>

								<template v-for="procedure in filteredProcedures">
									<div class="dropdown-item" @click.stop="selectOptionFilter('selectedProcedures', procedure.id)" v-bind:class="{active: selectedProcedures.includes(procedure.id)}" :key="'filter-procedure-' + procedure.id">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ procedure.name }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedStatus">{{ $t('filters.by_status') }}</span>
								<span class="text" v-else>{{ $t(`user_archivate.status.${selectedStatus}`) }}</span>

								<div class="icon-filter" v-if="!selectedStatus"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedStatus')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<template v-for="status in usersStatusList">
									<div class="dropdown-item" @click.stop="selectSingleOptionFilter('selectedStatus', status)" v-bind:class="{active: selectedStatus.includes(status)}" :key="'filter-status-' + status">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t(`user_archivate.status.${status}`) }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
							<icon-trash v-if="$resize && $mq.above(501)"/>
							<div v-else-if="$resize && $mq.below(500)">{{$t('filters.clear_all')}}</div>
						</button>
					</div>
					<div class="space-right"></div>
				</div>

				<div class="row-space-tbf search-row with-border">
					<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
					<div class="content">
						<div class="search-input">
							<input type="text" :placeholder="$t('general.search_by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</div>
			<loader-header-list-without-btn v-else/>

			<template v-if="loadedList">
				<div class="data-list list-goals" v-if="users.length">
					<div class="row-space-tbf header-list">
						<div class="space-left"></div>
						<div class="content">
							<div class="column-filter sortable column-name" @click="sortList('first_name')" v-bind:class="[sortBy == 'first_name' ? 'sort ' + sortDirection : '']">
								<div class="text">{{ $t('general.name') }}</div>
								<div class="icon-filter"><icon-arrow /></div>
							</div>
							<div v-if="$resize && $mq.above(424)" class="column-filter column-roles">
								<div class="text">{{ $t('users.roles') }}</div>
							</div>
							<div v-if="$resize && $mq.above(1605)" class="column-filter column-entries">
								<div class="text">{{ $t('users.entries') }}</div>
							</div>
							<div v-if="$resize && $mq.above(1240)" class="column-filter column-procedures-check">
								<div class="text">{{ $t('users.procedures_check') }}</div>
							</div>
							<div v-if="$resize && $mq.above(1090)" class="column-filter column-total_time">
								<div class="text">{{ $t('users.total_time') }}</div>
							</div>
							<div v-if="$resize && $mq.above(900)" class="column-filter column-nr-processes">
								<div class="text">{{ $t('users.processes_responsible') }}</div>
							</div>
							<div v-if="$resize && $mq.above(900)" class="column-filter column-nr-procedures">
								<div class="text">{{ $t('users.procedures_responsible') }}</div>
							</div>
							<div v-if="$resize && $mq.above(1395)" class="column-filter column-nr-updates-processes">
								<div class="text">{{ $t('users.count_updates_processes') }}</div>
							</div>
							<div v-if="$resize && $mq.above(1520)" class="column-filter column-nr-updates-procedures">
								<div class="text">{{ $t('users.count_updates_procedures') }}</div>
							</div>
							<!-- <div class="column-filter column-user"></div> -->
						</div>
						<div class="space-right"></div>
					</div>

					<template v-for="user in users">
						<div class="row-space-tbf row-list-item" :key="user.id">
							<div class="space-left"><icon-people v-if="$resize && $mq.above(781)"/></div>
							<div class="content">
								<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
								<div class="column-name-desc column-avatar column-name">
									<img :src="user.avatar" v-if="user.avatar">
									<div class="user-circle" v-else>
										<icon-user />
									</div>
									<div class="text">
										<div class="name">{{ user.name }}</div>
										<div class="description" v-if="!user.dummy_account">{{ user.email }}</div>
									</div>
								</div>
								<div v-if="$resize && $mq.above(424)" class="column-simple-text column-roles">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
										<div class="text roles">
											<span class="first" v-if="user.roles.length == 1">{{ user.roles[0] }}</span>
											<span v-else-if="user.roles.length > 1">{{`${user.roles[0]} (+${(user.roles.length - 1)})`}}</span>
										</div>
										<template slot="popover">
											<div class="simple-text"><span v-for="(role, index) in user.roles">{{index < user.roles.length - 1 ? role + ', ' : role}}</span></div>
										</template>
									</v-popover>
								</div>
								<div v-if="$resize && $mq.above(1605)" class="column-simple-text column-entries">
									<div class="text">{{user.analytics.entries}}</div>
								</div>
								<div v-if="$resize && $mq.above(1240)" class="column-simple-text column-procedures-check">
									<div class="text" @click="showModal('report_procedures_checks', {slug: user.slug, type: 'user', name: user.name})">{{ user.procedure_checks.run_checks }}</div>
								</div>
								<div v-if="$resize && $mq.above(1090)" class="column-simple-text column-total_time">
									<div class="text">{{user.analytics.total_time}}</div>
								</div>
								<div v-if="$resize && $mq.above(900)" class="column-simple-text column-nr-processes">
									<div class="text">{{user.processes_responsible}}</div>
								</div>
								<div v-if="$resize && $mq.above(900)" class="column-simple-text column-nr-procedures">
									<div class="text">{{user.procedures_responsible}}</div>
								</div>
								<div v-if="$resize && $mq.above(1395)" class="column-simple-text column-nr-updates-processes">
									<div class="text">{{user.improvement_proposals.processes}}</div>
								</div>
								<div v-if="$resize && $mq.above(1520)" class="column-simple-text column-nr-updates-procedures">
									<div class="text">{{user.improvement_proposals.procedures}}</div>
								</div>
							</div>
							<div class="space-right"></div>
						</div>
					</template>
				</div>
				<div class="row-space-tbf list-empty" v-else>
					<div class="space-left"></div>
					<div class="content full">
						<div class="title">{{ $t('empty.report-title')}}</div>
					</div>
					<div class="space-right"></div>
				</div>
			</template>
			<loader-items-list v-else/>

			<infinite-loading :identifier="infiniteId" @infinite="infiniteUsers" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>

<script>
	import NotModule from '@/components/General/NotModule'
    import IconArrow from '../../Icons/Arrow'
    import IconUser from '../../Icons/User'
    import IconPeople from '../../Icons/People'
    import IconEdit from '../../Icons/EditDots'
	import IconSearch from '../../Icons/Search'
	import IconSettings from '../../Icons/Settings'
	import IconTrash from '../../Icons/Trash'
	import IconClose from '../../Icons/Close'
    import LoaderItemsList from '../../PagesLoaders/ItemsList'
    import LoaderHeaderListWithoutBtn from '../../PagesLoaders/HeaderListWithoutBtn'
	import InfiniteLoading from 'vue-infinite-loading'
	import IconDownload from '../../Icons/Download'

    export default {
    	components: {
			NotModule,
            IconArrow,
            IconUser,
            IconPeople,
            IconEdit,
			IconSearch,
			IconSettings,
			IconTrash,
			IconClose,
            LoaderItemsList,
            LoaderHeaderListWithoutBtn,
            IconDownload,
			InfiniteLoading
        },
        data() {
            return {
            	loaded: false,
            	loadedList: false,
            	users: [],
            	sortBy: '',
            	sortDirection: 'asc',
				search_filter: '',
				queryFiltersCount: 0,
            	extendFilters: false,
            	filterRoles: [],
            	selectedRoles: [],
            	searchRoles: '',
            	filterProcedures: [],
            	selectedProcedures: [],
            	searchProcedures: '',
				page: 0,
            	infiniteId: 1,
            	usersStatusList: ['active', 'archived'],
            	selectedStatus: ''
            }
        },
        async mounted(){
			if( this.soft_procedures.is_active) {
				this.$refs.infiniteLoading.status = 1
				this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
			}

			await this.getFilters()
			this.checkQueryFilters()

			this.$root.$on('refreshUsersIndex', () => {
				this.getFilters()
				this.checkQueryFilters()
			})
        },
       watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
        computed: {
			filteredRoles(){
				return getByKeywordFilter(this.filterRoles, this.searchRoles)
			},
			filteredProcedures(){
				return getByKeywordFilter(this.filterProcedures, this.searchProcedures)
			},
			soft_procedures() {
				return this.$store.getters['applications/getApplication']('Proceduri');
			}
        },
        methods: {
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {roles: true, procedures: true }})
				.then(({data}) => {
					this.filterRoles = data.data.roles
        			this.filterProcedures = data.data.procedures
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
			infiniteUsers($state){
				var paramsCall = { view_more: this.page * 10}
				this.queryObject(paramsCall)

				if(this.sortBy){
        			paramsCall.sort_by = this.sortBy
        			paramsCall.sort_direction = this.sortDirection
        		}

				axios.get('/reports/' + this.$auth.user().instance.id + '/people', { params: paramsCall })
				.then(({data}) => {
					if(data.data.length) {
						this.page++
						this.users.push(...data.data)
						setTimeout(() => { $state.loaded() }, 200)
        				if(data.data.length < 10){
        					$state.complete()
        				}
					} else{
						$state.complete()
					}

        			this.loadedList = true
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }
					if(key == 'roles'){ paramsCall.role_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'procedures'){ paramsCall.procedure_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'status'){ paramsCall.status = dataQuery[key].split(',') }
				});
			},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;
				this.searchFilterFunct()
			},
			editUser(userSlug){
				this.$router.push({ name: 'user-edit', params: {slug: userSlug} })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			donwloadXLS() {
				var showColumnsObj = ['name', 'email', 'roles', 'analytics.entries', 'analytics.total_time', 'processes_responsible', 'procedures_responsible', 'improvement_proposals.processes', 'improvement_proposals.procedures']

				var paramsCall = {}
				this.queryObject(paramsCall)
				paramsCall.type = 'xls'
				paramsCall.show_columns = showColumnsObj

				axios.get('/reports/' + this.$auth.user().instance.id + '/people', 
				{
					params: paramsCall,
                	responseType: 'blob'
				})
				.then((response) => {

					let blob = new Blob([response.data], { type:"application/octet-stream" })
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = this.$t('reports.users') + '.xlsx';

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
            },
			selectOptionFilter(filter, value) {
				if(typeof value == 'string'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
				} else {
					if(this[filter].includes(parseInt(value))){
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
				}

				this.addFilterToRouteQuery(filter)
        	},
        	selectSingleOptionFilter(filter, value){
        		if(this[filter] != value) {
	        		this[filter] = value
        		}else{
        			this[filter] = ''
        		}
        		
        		this.addFilterToRouteQuery(filter)
        	},
        	addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedRoles' || filterName == 'all'){ dataQuery['roles'] = this.selectedRoles.toString(); }
				if(filterName == 'selectedProcedures' || filterName == 'all'){ dataQuery['procedures'] = this.selectedProcedures.toString(); }
				if(filterName == 'selectedStatus' || filterName == 'all'){ dataQuery['status'] = this.selectedStatus.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length - 1

				this.$router.push({query : dataQuery });
				this.searchFilterFunct()
			},
			clearFilter(filter){
				if(filter == 'all'){
					this.selectedRoles = []
					this.selectedProcedures = []
				}else{
					if(filter == 'selectedStatus') {
						this[filter] = ''
					} else {
						this[filter] = []
					}
				}

				this.addFilterToRouteQuery(filter)
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }
					if(key == 'roles'){ this.selectedRoles = dataQuery[key].split(',').map(Number) }
					if(key == 'procedures'){ this.selectedProcedures = dataQuery[key].split(',').map(Number) }
					if(key == 'status'){ this.selectedStatus = dataQuery[key].split(',') }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 2 : Object.keys(dataQuery).length - 1
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.users = []
				this.$refs.infiniteLoading.status = 1
				this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
			},
        }
    }
	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
	.row-space-tbf.search-row{
		&.reports {
			padding-top: 0;
		}
	} 
	.list-goals{
		.view-more{
            width: 100%;
            a{
                width: 100%;
                height: 38px;
                .text{
                    color: $grey;
                }
            }
        }
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}
		.column-avatar {
			&.column-name{
				.user-circle,
				img {
					margin-right: 20px;
					min-width: 36px;
				}
				.text{
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
			}
		}
		.column-name{
			flex: 1 1 auto;
			display: flex;
			align-items: center;
		}
		.column-entries,
        .column-total_time{
			flex: 0 0 100px;
		}
		.column-nr-processes,
		.column-nr-procedures,
		.column-nr-updates-procedures,
		.column-nr-updates-processes,
		.column-procedures-check{
			flex: 0 0 120px;
		}
		.column-procedures-check{
			.text{
				cursor: pointer;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		.column-user{
			flex: 0 0 37px;
			display: flex;
			justify-content: flex-end;
		}
		.column-roles{
			flex: 0 0 150px;
			.roles{
				display: flex;
				.word{
					padding-right: 10px;
				}
				.number{
					padding-right: 10px;
					text-align: center;
					min-width: 57px;
					line-height: 16px;
					padding-bottom: 3px;
					margin: auto;
				}
			}
		}
		@media (max-width: 1350px) {
			.column-entries,
			.column-total_time,
			.column-nr-processes,
			.column-nr-procedures,
			.column-nr-updates-procedures,
			.column-nr-updates-processes{
				flex: 0 0 100px;
			}
		}
	}
	.list-empty .content {
		padding: 10px;
		padding-left: 0px;
		border-top: none;
		color: $grey
	}
</style>